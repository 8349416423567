



























import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import ability from '../ability';

@Component({})
export default class Dashboard extends Vue {
  public $store: any;
  public loading: boolean = false;

  public beforeCreate() {
    if (this.$store.state.buildings.length === 0) {
      this.loading = false;
      return;
    }
    const firstBuilding = this.$store.state.buildings[0];
    this.$router.push({name: 'dashboard-building', params: {id: firstBuilding.id}});
  }

}
